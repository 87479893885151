"use client";

import { FC, useEffect, useRef, useState } from "react";
import { useFormState } from "react-dom";
import { useReCaptcha } from "next-recaptcha-v3";

import { CollectionForm } from "@Shared/api";
import { GOOGLE_RECAPTCHA_SITE_KEY } from "@Shared/config";
import { ElProps } from "@Shared/types";
import { ButtonSubmit, Input, Text } from "@Shared/ui";
import { googleRecaptchaFormatActionName, twcx } from "@Shared/utils";

import { sendForm } from "../../actions";
import { FormStateModel } from "../../models";
import { Interests } from "./Interests";
import { Success } from "./Success";

const initialState: FormStateModel = {
    success: false,
    error: null,
};

export type FormProps = ElProps<"form"> & {
    form: CollectionForm;
};

export const Form: FC<FormProps> = ({ className, form, ...restProps }) => {
    const refForm = useRef<HTMLFormElement>(null);

    const [interests, setInterests] = useState<string>("");
    const [isShowSuccess, setIsShowSuccess] = useState<boolean>(false);

    const { executeRecaptcha } = useReCaptcha();

    const [formState, formAction] = useFormState(async (prevState: FormStateModel, formData: FormData) => {
        let recaptchaToken;

        if (GOOGLE_RECAPTCHA_SITE_KEY) {
            try {
                recaptchaToken = await executeRecaptcha(`form_submit_${googleRecaptchaFormatActionName(form.slug)}`);
            } catch (e) {
                console.error(e);
            }
        }

        return sendForm({ prevState, formData, recaptchaToken });
    }, initialState);

    useEffect(() => {
        if (formState.error?.fields) {
            document.getElementsByName(Object.keys(formState.error.fields)[0])?.[0]?.focus();
        }

        if (formState.success) {
            refForm.current?.reset();

            setInterests("");
            setIsShowSuccess(true);
        }
    }, [formState]);

    return (
        <>
            <form
                ref={refForm}
                className={twcx("mb-4 flex flex-col gap-6 last:mb-0", "lg:-ml-6 lg:mb-0 lg:flex-1", className)}
                action={formAction}
                {...restProps}
            >
                <div className={twcx("flex flex-col gap-4", "lg:flex-row lg:items-end")}>
                    {form.fields.map((field, index) => {
                        if (field.name !== "name" && field.name !== "email") {
                            return null;
                        }

                        const key = index + field.name;

                        return (
                            <Input
                                key={key}
                                className="lg:flex-1"
                                classNames={{ label: "text-blue-light" }}
                                id={`${form.slug}-${field.name}`}
                                label={field.label}
                                type={field.type}
                                name={field.name}
                                placeholder={field.placeholder}
                                defaultValue={field.defaultValue}
                                required={field.required}
                                isTextarea={field.type === "textarea"}
                                error={!!formState.error?.fields?.[field.name]}
                            />
                        );
                    })}
                </div>

                {form.fields.map((field, index) => {
                    if (field.name === "name" || field.name === "email") {
                        return null;
                    }

                    const key = index + field.name;

                    if (field.type !== "checkboxList" && field.type !== "select") {
                        return (
                            <Input
                                key={key}
                                classNames={{ label: "text-blue-light" }}
                                id={`${form.slug}-${field.name}}`}
                                label={field.label}
                                type={field.type}
                                name={field.name}
                                placeholder={field.placeholder}
                                defaultValue={field.defaultValue}
                                required={field.required}
                                isTextarea={field.type === "textarea"}
                                error={!!formState.error?.fields?.[field.name]}
                            />
                        );
                    } else if (field.type === "checkboxList") {
                        return <Interests key={key} field={field} value={interests} setValue={setInterests} />;
                    }

                    return null;
                })}

                <ButtonSubmit
                    className={twcx("shrink-0", "lg:mt-2")}
                    variant="tertiary"
                    block
                    loadingContent={form.buttonSubmitLoadingText ?? undefined}
                >
                    {form.buttonSubmitText}
                </ButtonSubmit>

                {(form.caption || formState.error) && (
                    <div className={twcx("-mt-3 flex flex-col gap-3", "lg:-mt-2 lg:gap-4")}>
                        {formState.error?.message && (
                            <Text variant="p2" weight={500} color="red">
                                {formState.error.message}
                            </Text>
                        )}

                        {formState.error?.fields &&
                            Object.entries(formState.error.fields).map(([key, value]) => (
                                <Text key={key} variant="p2" weight={500} color="red">
                                    {value}
                                </Text>
                            ))}

                        {form.caption && (
                            <Text variant="p3" weight={500} color="gray-03">
                                {form.caption}
                            </Text>
                        )}
                    </div>
                )}
            </form>

            <Success
                isOpen={isShowSuccess}
                setIsOpen={setIsShowSuccess}
                title={form.resultSuccessTitle}
                text={form.resultSuccessText}
            />
        </>
    );
};
