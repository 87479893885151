"use client";

import { FC, useEffect } from "react";
import { useKeyPressEvent } from "react-use";
import { useParams } from "next/navigation";

import { MODALS } from "@Shared/config";
import { useModalState } from "@Shared/store";
import { ElProps } from "@Shared/types";
import { ContainerSection, Wrapper } from "@Shared/ui";
import { twcx } from "@Shared/utils";

export type ContainerProps = ElProps<"nav">;

export const Container: FC<ContainerProps> = ({ className, children, ...restProps }) => {
    const params = useParams();
    const { create, isOpen: modalIsOpen, close } = useModalState();

    const isOpen = modalIsOpen(MODALS.contactUs);

    useKeyPressEvent("Escape", () => (isOpen ? close(MODALS.contactUs) : null));

    useEffect(() => {
        create(MODALS.contactUs);
    }, []);

    useEffect(() => {
        isOpen && close(MODALS.contactUs);
    }, [params]);

    return (
        <Wrapper
            className={twcx(
                "fixed inset-0 z-30 flex overflow-hidden transition-[opacity,transform]",
                {
                    "pointer-events-none scale-95 opacity-0": !isOpen,
                },
                className
            )}
            {...restProps}
        >
            <ContainerSection
                className={twcx(
                    "flex pt-[var(--header-height)]",
                    "before:pointer-events-none before:absolute before:left-0 before:right-0 before:top-[var(--header-height)] before:z-10 before:h-2 before:bg-gradient-to-b before:from-black",
                    "after:pointer-events-none after:absolute after:bottom-0 after:left-0 after:right-0 after:z-10 after:h-6 after:bg-gradient-to-t after:from-black",
                    "lg:before:h-12",
                    "lg:after:h-12"
                )}
            >
                {children}
            </ContainerSection>
        </Wrapper>
    );
};
