"use client";

import { FC, useEffect, useRef } from "react";
import { useClickAway, useKeyPressEvent } from "react-use";
import { useParams } from "next/navigation";

import { MODALS } from "@Shared/config";
import { useTranslation } from "@Shared/i18n";
import { useModalState } from "@Shared/store";
import { ElProps } from "@Shared/types";
import { Icon, Text } from "@Shared/ui";
import { twcx } from "@Shared/utils";

export type ModalProjectSoonProps = ElProps<"div">;

export const ModalProjectSoon: FC<ModalProjectSoonProps> = ({ className, ...restProps }) => {
    const refContainer = useRef<HTMLDivElement>(null);

    const { t } = useTranslation("common");
    const params = useParams();
    const { create, isOpen: modalIsOpen, close } = useModalState();

    const isOpen = modalIsOpen(MODALS.projectSoon);

    useKeyPressEvent("Escape", () => (isOpen ? close(MODALS.projectSoon) : null));
    useClickAway(refContainer, () => (isOpen ? close(MODALS.projectSoon) : null));

    useEffect(() => {
        create(MODALS.projectSoon);
    }, []);

    useEffect(() => {
        isOpen && close(MODALS.projectSoon);
    }, [params]);

    return (
        <div
            className={twcx(
                "scroll-container-y fixed inset-0 z-10 flex bg-black bg-opacity-75 p-5 transition-opacity",
                "lg:p-10",
                {
                    "pointer-events-none opacity-0": !isOpen,
                },
                className
            )}
            {...restProps}
        >
            <div
                ref={refContainer}
                className={twcx(
                    "relative m-auto flex min-h-[200px] w-full max-w-[440px] flex-col items-center justify-center gap-2 overflow-hidden rounded-xl bg-white px-3 py-12 text-center text-black shadow-[0_12px_30px_0_rgba(0,0,0,0.15)] transition-transform",
                    "lg:min-h-[329px] lg:max-w-[598px] lg:gap-5 lg:rounded-[20px] lg:px-10",
                    {
                        "scale-95": !isOpen,
                    }
                )}
            >
                <button
                    className={twcx(
                        "absolute right-1 top-1 z-10 flex size-10 items-center justify-center",
                        "lg:right-2 lg:top-2 lg:transition-colors lg:hover:text-blue-bright"
                    )}
                    aria-label="Close modal"
                    onClick={() => close(MODALS.projectSoon)}
                >
                    <Icon k="close" />
                </button>

                <Text className={twcx("flex items-center gap-2", "lg:gap-3")} as="h3" variant="h3" weight={600}>
                    {t("modals.projectSoon.title")}
                </Text>

                <Text className="lg:max-w-[400px]" as="p" variant="p2" weight={500} color="gray-03">
                    {t("modals.projectSoon.text")}
                </Text>
            </div>
        </div>
    );
};
